<template>
  <ion-page>    
    <ion-content :fullscreen="true" >

        <template v-if="!loading">
            <div class="ios hydrated container-page-header">
                <div class="page-title">
                    <h1>Complete & Send</h1>
                </div>
                <div class="job-title">{{ title }}</div>
                <div class="job-code">#{{ job.job_code }}</div>
            </div>

            <div class="notes-field">
                <div class="title">Your Notes:</div>
                <p class="text">{{ notes }}</p>
            </div>
        </template>


    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-tabs>
                <ion-router-outlet></ion-router-outlet>
                <ion-tab-bar slot="bottom" color="primary">
                    <ion-tab-button tab="cancel" color="primary" @click="cancel">
                        <uil-times size="25px"/>
                        <ion-label>Cancel</ion-label>
                    </ion-tab-button>

                    <ion-tab-button tab="sendnow" @click="sendNow">
                        <uil-check size="25px"/>
                        <ion-label>Send Now</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonTabBar, IonTabButton, IonTabs, alertController, 
    toastController, modalController, IonLabel, IonToolbar, IonFooter, IonRouterOutlet } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { UilTimes, UilCheck } from '@iconscout/vue-unicons';
import LoadingModal from '../modals/Loading.vue';

export default {
    name: 'Home',
    components: {
        IonContent, IonPage, IonTabBar, IonTabButton, IonTabs, UilTimes, UilCheck,
        IonLabel, IonToolbar, IonFooter, IonRouterOutlet
    },
    data () {
        return {
            job_id: this.$route.params.id,
            job : [],
            asset : [],
            notes : "",
            loadingModal : null,
            title: "",
            loading: true
        }
    },
    methods: {
        makeTitle(){
            if(this.asset){
                this.title = this.asset.address;
                if(this.asset.address2 != null){
                    this.title += ", " + this.asset.address2;
                }
                if(this.asset.address3 != null){
                    this.title += ", " + this.asset.address3;
                }
                if(this.asset.city != null){
                    this.title += ", " + this.asset.city;
                }
                if(this.asset.postcode != null){
                    this.title += ", " + this.asset.postcode;
                }
            }
        },
        cancel(){
            this.$router.back()
        },
        send24hr(){
            this.networkCheck("24hr")
        },
        sendNow(){
            this.networkCheck("now")
        },
        networkCheck(type){
            
            var network = this.getNetwork;
            var options = {
                id : this.job.id,
                type: type
            }
            if(network.connectionType == "none"){
                // we are now offline
                this.offlineAlert();
            } else if(network.connectionType == "cellular"){
                // warn the user that it will use data and they should have good service
                // recommend wifi
                this.presentAlertConfirm(options);
            } else {
                this.pushJob(options);
                
            }

        },
        async presentAlertConfirm(options) {
            const alert = await alertController
            .create({
                cssClass: 'cellular-alert',
                header: 'Mobile Data Alert!',
                message: 'You are currently using mobile data. When saving a survey we recommend using WiFi or a strong cellular connection. Please note that continuing will also contribute to your data usage.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Continue',
                        handler: () => {
                            this.pushJob(options);
                        },
                    },
                ],
            });
            return alert.present();
        },
        async pushJob(options){
            let vm = this;
            this.loadingTriggered();
            await this.$store.dispatch("brightchecker/sendJob", options).then(success => {
                console.log("dis", success)
                if(success){
                    vm.closeModal();
                    vm.saveSuccess();
                } else {
                    vm.closeModal();
                    vm.saveFail();
                }
            })
        },
        async offlineAlert() {
            const alert = await alertController
                .create({
                    cssClass: 'offline-alert',
                    header: 'You are offline!',
                    message: 'To save a survey, please connect to a WiFi (recommended) or cellular network.',
                    buttons: ['OK'],
            });
            return alert.present();
        },
        async saveSuccess() {
            // const toast = await toastController
            // .create({
            //     message: 'The job has been saved.',
            //     duration: 20000,
            //     cssClass: "saved-job"
            // })
            // await toast.present();

            // await toast.onDidDismiss();
            const alert = await alertController
                .create({
                    cssClass: 'save-alert',
                    header: 'Job Saved',
                    message: 'The job has been successfully saved.',
                    buttons: ['OK'],
            });

            await alert.present();

            alert.onDidDismiss().then(() => {
                this.$router.push({ name : 'home' })
            });

        },
        async saveFail() {
            const toast = await toastController
            .create({
                message: 'The job failed to save.',
                duration: 20000,
                cssClass: "failed-job"
            })
            await toast.present();

            await toast.onDidDismiss();
        },
        async loadingTriggered(){
            this.loadingModal = await modalController
            .create({
                component: LoadingModal,
                cssClass: 'loading-modal',
            })
            return this.loadingModal.present();
        },
        async closeModal(){
            this.loadingModal.dismiss();
        },
        pageSetup(){
            this.job = this.getJob;

            this.asset = this.job.asset;
            this.makeTitle()

            this.notes = "There are no surveyor notes for this job.";
            if(this.asset){
                this.notes = this.asset.notes;
            }
            this.loading = false;
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            getJob: 'brightchecker/current_job',
            getNetwork: 'auth/network'
        }),
    },
    mounted() {
        // this.pageSetup();
    },
    updated(){
        console.log(this.$route.params.id)
        this.loading = true;
        this.pageSetup();
    }
}
</script>

<style scoped lang="scss">
    h1, h2 {
        text-align: center;
        margin-top: 0px;
        font-size: 20px;
        font-weight: 800;
        line-height: 30px;
        color: #373636;
    }


    ion-toolbar {
        --min-height: 50px;
    }

    ion-tab-button {
        svg {
            color: #DB9123;
        }
        --background: #172943;
        color: #ffffff;
    }

    .page-title {
        margin-bottom: 30px;
    }

    .job-title, .job-code {
        font-size: 16px;
        font-weight: 800;
        line-height: 19px;
        color: #373636;
        font-family: 'Lato', sans-serif;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .container-page-header {
        padding: 0px 20px;
    }

    .notes-field {
        background-color: #ffffff;
        padding: 20px;
        margin: 40px 30px 0px 30px;
        box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
        .title {
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 5px;
        }
        .text {
            font-size: 14px;
            font-weight: 400;
            margin: 0px;
        }
    }

</style>